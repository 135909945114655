'use strict';
import sxQuery from '../sxQuery/sxQuery';
import Helper from '../siteSearch/utils/Helper';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
var InsightsRequestType;
(function (InsightsRequestType) {
    InsightsRequestType["Post"] = "POST";
    InsightsRequestType["Get"] = "GET";
    InsightsRequestType["Image"] = "IMAGE";
})(InsightsRequestType || (InsightsRequestType = {}));
var SELECTED_LINK_KEY = 'ss360SelectedLinks';
var SELECTED_IDENTIFIER_KEY = 'ss360SelectedIdentifiers';
var SELECTED_ARTICLE_NUMBER_KEY = 'ss360SelectedArticleNumbers';
var getStringValue = function (val) {
    if (val === undefined) {
        return '---';
    }
    return val.trim();
};
var encodeComponent = function (component) { return encodeURIComponent(component !== undefined && component.split ? component.split('/').join('___') : component); };
var DAY_MS = 24 * 60 * 60 * 1000; // day in ms
var SESSION_DURATION_MS = 10 * 60 * 1000; // session duration after last interaction
var CHECKOUT_RETENTION_DAYS = 14; // we remove all checkout related entries after 14 days with no interaction
var getNumberValue = function (val) {
    if (val === undefined || Helper.isNaN(val)) {
        return -1;
    }
    return val;
};
var generateElementSelector = function (el, checkParent, encodeUri) {
    if (checkParent === void 0) { checkParent = true; }
    if (encodeUri === void 0) { encodeUri = false; }
    if (!el) {
        return undefined;
    }
    var className = '';
    try {
        className = el.className && el.className ? ".".concat(el.className.split(' ').join('.')) : '';
    }
    catch (ex) {
        className = (el.getAttribute ? el.getAttribute('class') : '') || '';
    }
    var id = el.getAttribute('id') ? "#".concat(el.getAttribute('id')) : '';
    var parentEl = el.parentNode;
    var selector = '';
    if (parentEl && checkParent) {
        // @ts-ignore
        selector = "".concat(generateElementSelector(parentEl, false), " ");
    }
    selector += el.tagName.toLowerCase() + id + className;
    return encodeUri ? encodeURIComponent(selector) : selector;
};
var removeOutdatedInteractionObject = function (fullKey, entry) {
    if (entry === undefined || entry === null || entry.updated === undefined || entry.updated === null) {
        return;
    }
    // remove everything older than CHECKOUT_RETENTION_DAYS days
    var start = new Date().getTime() - CHECKOUT_RETENTION_DAYS * DAY_MS;
    if (entry.updated < start) {
        SxQueryUtils._storeObject(fullKey, null);
    }
};
var normalizeLink = function (link) {
    if (link === undefined || link === null || link === '') {
        return link;
    }
    var hasProtocol = link.indexOf('http://') !== -1 || link.indexOf('https://') !== -1;
    if (hasProtocol) {
        return link;
    }
    if (link.indexOf('//') === 0) {
        return "".concat(window.location.protocol).concat(link);
    }
    if (link.indexOf('/') !== 0) {
        link = "/".concat(link);
    }
    return "".concat(window.location.protocol, "//").concat(window.location.host).concat(link);
};
var getOrCreateZoovuCid = function (sessionId) {
    var zoovuCid = SxQueryUtils.readCookie('zoovu-cid');
    if (zoovuCid === null) {
        zoovuCid = "".concat(sessionId, "_").concat(new Date().getTime());
        SxQueryUtils.createCookie('zoovu-cid', zoovuCid, 365);
    }
    return zoovuCid;
};
var SS360Insights = /** @class */ (function () {
    function SS360Insights(siteId, allowCookies, trackingConfig, cookiePostfix, generateZoovuCid) {
        if (generateZoovuCid === void 0) { generateZoovuCid = false; }
        this.generateZoovuCid = false;
        this.debugMethod = function (_message) { };
        this.siteId = siteId;
        this.allowCookies = allowCookies;
        this.searchQueryCache = { query: undefined, timestamp: undefined };
        this.suggestionQueryCache = { query: undefined, timestamp: undefined };
        this.cookiePostfix = cookiePostfix;
        this.hasCheckoutTracking = false;
        this.generateZoovuCid = generateZoovuCid;
        this.config = {
            baseUrl: 'https://insights.sitesearch360.com/insights',
            endpoints: {
                // eslint-disable-next-line max-len
                serpSelect: '/v2/serp/select/##siteId##/##sessionId##/##timestamp##/##query##/##resultCount##/##position##/##positionInContentGroup##/##link##/##searchResultType##/##referrer##',
                searchBoxFocus: '/searchbox/focus',
                searchBoxBulkChange: '/searchbox/change/bulk',
                searchBoxAbandon: '/searchbox/abandon',
                // eslint-disable-next-line max-len
                searchSubmit: '/v2/search/submit/##siteId##/##sessionId##/##timestamp##/##query##/##searchBoxId##/##searchButtonId##/##referrer##',
                suggestShow: '/suggests/show',
                // eslint-disable-next-line max-len
                suggestSelect: '/v2/suggests/select/##siteId##/##sessionId##/##timestamp##/##query##/##resultCount##/##searchBoxId##/##position##/##link##/##referrer##',
                serpShow: '/serp/show',
                // eslint-disable-next-line max-len
                serpAbandon: '/v2/serp/abandon/##siteId##/##sessionId##/##timestamp##/##query##/##resultCount##/##searchResultType##/##referrer##',
                filterInteraction: '/filter/interaction',
                addToCart: '/checkout/cart',
                buy: '/checkout/buy',
                relatedContentLoaded: '/related-content/load/##siteId##/##sessionId##/##timestamp##/##duration##/##query##/##count##/##referrer##/##id##',
                relatedContentViewed: '/related-content/view/##siteId##/##sessionId##/##timestamp##/##query##/##id##',
                relatedContentClicked: '/related-content/click/##siteId##/##sessionId##/##timestamp##/##query##/##resultLink##/##position##/##id##',
                guidedQuestionLoaded: '/guided-question/load/##siteId##/##sessionId##/##timestamp##/##duration##/##query##/##referrer##/##id##/##viewId##',
                guidedQuestionViewed: '/guided-question/view/##siteId##/##sessionId##/##timestamp##/##query##/##viewId##',
                guidedQuestionClicked: '/guided-question/click/##siteId##/##sessionId##/##timestamp##/##query##/##viewId##/##position##'
            }
        };
        if (trackingConfig.enhancedParams !== undefined) {
            this.additionalParameters = trackingConfig.enhancedParams;
        }
        else {
            this.additionalParameters = {};
        }
        if (trackingConfig.userGroup !== undefined) {
            this.additionalParameters.userGroup = trackingConfig.userGroup;
        }
        if (trackingConfig.queryTag !== undefined) {
            this.additionalParameters.queryTag = trackingConfig.queryTag;
        }
        if (trackingConfig.enhancedBaseUrl !== undefined) {
            this.config.baseUrl = trackingConfig.enhancedBaseUrl;
        }
        this.sessionId = this.getSessionId();
        this.searchBoxChanges = {};
        this.latestSearchBoxValues = {};
        // reset outdated entries
        var selectedLinks = this.loadSearchInteractionEntry(SELECTED_LINK_KEY);
        var selectedIdentifiers = this.loadSearchInteractionEntry(SELECTED_IDENTIFIER_KEY);
        var selectedArticleNumbers = this.loadSearchInteractionEntry(SELECTED_ARTICLE_NUMBER_KEY);
        removeOutdatedInteractionObject(this.getSearchInteractionEntryKey(SELECTED_LINK_KEY), selectedLinks);
        removeOutdatedInteractionObject(this.getSearchInteractionEntryKey(SELECTED_IDENTIFIER_KEY), selectedIdentifiers);
        removeOutdatedInteractionObject(this.getSearchInteractionEntryKey(SELECTED_ARTICLE_NUMBER_KEY), selectedArticleNumbers);
    }
    SS360Insights.prototype.updateLastInteractionTime = function () {
        SxQueryUtils.createCookie("ssi--lastInteraction".concat(this.cookiePostfix), new Date().getTime().toString(), (SESSION_DURATION_MS) / DAY_MS);
    };
    SS360Insights.prototype.loadSessionId = function () { return SxQueryUtils.readCookie("ssi--sessionId".concat(this.cookiePostfix)); };
    SS360Insights.prototype.loadLastInteraction = function () {
        var lastInteraction = SxQueryUtils.readCookie("ssi--lastInteraction".concat(this.cookiePostfix));
        return lastInteraction !== undefined && lastInteraction !== null ? parseInt(lastInteraction, 10) : undefined;
    };
    SS360Insights.prototype.getSessionId = function () {
        if (!this.allowCookies) {
            return this.generateSessionId();
        }
        var sessionId = this.loadSessionId();
        if (sessionId === null) {
            return this.generateSessionId();
        }
        var lastInteraction = this.loadLastInteraction();
        if (lastInteraction === undefined || (new Date().getTime() - lastInteraction) > SESSION_DURATION_MS) {
            return this.generateSessionId(sessionId);
        }
        return sessionId;
    };
    SS360Insights.prototype.generateSessionId = function (previousSessionId) {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        var sessionId = "".concat(s4() + s4(), "-").concat(s4(), "-").concat(s4(), "-").concat(s4(), "-").concat(s4()).concat(s4()).concat(s4());
        var baseUrl = this.config.baseUrl;
        var additionalParamString = '';
        // eslint-disable-next-line no-undef
        // @ts-ignore
        if (SS360_VERSION !== undefined) {
            var versionNumber = void 0;
            // eslint-disable-next-line no-undef
            // @ts-ignore
            var versionParts = SS360_VERSION.toString().split('.'); // major.minor.patch
            if (versionParts.length > 1) {
                versionNumber = "".concat(versionParts[0], ".").concat(versionParts[1]);
            }
            if (versionNumber !== undefined) {
                additionalParamString += "version=".concat(encodeURIComponent(versionNumber));
            }
        }
        if (this.generateZoovuCid) {
            var zoovuCid = getOrCreateZoovuCid(sessionId);
            additionalParamString = additionalParamString.length > 0 ? "".concat(additionalParamString, "&") : '';
            additionalParamString = "".concat(additionalParamString, "zoovuCid=").concat(encodeURIComponent(zoovuCid));
        }
        var sessionLog = {
            type: InsightsRequestType.Post,
            url: "".concat(baseUrl, "/session").concat(additionalParamString.length > 0 ? '?' : '').concat(additionalParamString),
            data: {
                siteId: this.siteId,
                sessionId: sessionId,
                timestamp: new Date().getTime(),
                isPersistent: this.allowCookies,
                referrer: getStringValue(window.location.href)
            }
        };
        if (previousSessionId !== undefined) {
            // @ts-ignore
            sessionLog.data.prevSessionId = previousSessionId;
        }
        this.sendRequest(sessionLog);
        this.debugMethod('Created a search session');
        if (this.allowCookies) {
            SxQueryUtils.createCookie("ssi--sessionId".concat(this.cookiePostfix), sessionId, 365);
            this.updateLastInteractionTime(); // generating session id counts as interaction
        }
        return sessionId;
    };
    SS360Insights.prototype.setDebugMethod = function (debugMethod) {
        this.debugMethod = debugMethod;
    };
    SS360Insights.prototype.buildAdditionalParamsString = function () {
        var _this = this;
        if (this.additionalParameters === undefined || this.additionalParameters === null || Object.keys(this.additionalParameters).length === 0)
            return '';
        return Object.keys(this.additionalParameters).reduce(function (acc, key) { return "".concat(acc, "&").concat(encodeURIComponent(key), "=").concat(encodeURIComponent(_this.additionalParameters[key])); }, '').substring(1);
    };
    SS360Insights.prototype.sendRequest = function (request) {
        var additionalParameters = this.buildAdditionalParamsString();
        if (additionalParameters !== undefined && additionalParameters.length > 0) {
            var requestUrl = request.url;
            if (requestUrl.indexOf('?') === -1) {
                requestUrl = "".concat(requestUrl, "?");
            }
            else if (requestUrl[requestUrl.length - 1] !== '&' && requestUrl[requestUrl.length - 1] !== '?') {
                requestUrl = "".concat(requestUrl, "&");
            }
            request.url = "".concat(requestUrl).concat(additionalParameters);
        }
        if (request.type === InsightsRequestType.Image) {
            var i = new Image(1, 1);
            i.src = request.url;
        }
        else if (request.type === InsightsRequestType.Get) {
            SxQueryUtils.get(request.url);
        }
        else if (request.type === InsightsRequestType.Post) {
            SxQueryUtils.post(request.url, request.data);
        }
    };
    SS360Insights.prototype.createImageRequest = function (endpoint, sessionId, data) {
        Object.keys(data).forEach(function (key) {
            endpoint = endpoint.replace("##".concat(key, "##"), encodeComponent(data[key]));
        });
        endpoint = endpoint.replace('##siteId##', encodeComponent(this.siteId));
        endpoint = endpoint.replace('##sessionId##', encodeComponent(sessionId));
        endpoint = endpoint.replace('##timestamp##', encodeComponent(new Date().getTime().toString()));
        endpoint = endpoint.replace(/##.+?##/g, '');
        return {
            type: InsightsRequestType.Image,
            url: this.config.baseUrl + endpoint
        };
    };
    SS360Insights.prototype.pretrack = function () {
        if (this.allowCookies) {
            this.updateLastInteractionTime();
        }
    };
    SS360Insights.prototype.trackSerpClick = function (query, eventTarget, resultBlock, resultCount, position, positionInContentGroup, link, searchResultType, articleNumber, identifier, clickSource, contentGroup, originalContentGroup) {
        this.pretrack();
        var hasEventTarget = eventTarget !== undefined && eventTarget !== null;
        if (identifier === undefined || identifier === null) {
            // @ts-ignore
            identifier = hasEventTarget ? sxQuery(eventTarget).data('productIdentifier') : undefined;
        }
        var queryParam = '';
        if ((articleNumber === undefined || articleNumber === null) && hasEventTarget) {
            // @ts-ignore
            articleNumber = sxQuery(eventTarget).data('ss360ArticleNumber') || sxQuery(eventTarget).data('productArticleNumber');
        }
        var queryParams = {};
        if (identifier) {
            queryParams.identifier = identifier;
        }
        if (articleNumber) {
            queryParams.articleNumber = articleNumber;
        }
        if (clickSource) {
            queryParams.clickSource = clickSource;
        }
        if (contentGroup) {
            queryParams.contentGroup = contentGroup;
        }
        if (originalContentGroup) {
            queryParams.originalContentGroup = originalContentGroup;
        }
        if (Object.keys(queryParams).length > 0) {
            queryParam = "?".concat(Object.keys(queryParams).map(function (key) { return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(queryParams[key])); }).join('&'));
        }
        link = normalizeLink(link);
        this.storeSearchInteraction(link, identifier, articleNumber);
        var request = this.createImageRequest("".concat(this.config.endpoints.serpSelect).concat(queryParam), this.sessionId, {
            query: getStringValue(query),
            resultCount: getNumberValue(resultCount),
            position: getNumberValue(position),
            positionInContentGroup: getNumberValue(positionInContentGroup),
            link: getStringValue(link),
            searchResultType: getStringValue(searchResultType),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked search result click');
    };
    SS360Insights.prototype.trackSearchBoxFocus = function (searchBox) {
        this.pretrack();
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            searchBoxId: generateElementSelector(searchBox, true, true),
            referrer: getStringValue(window.location.href),
            query: getStringValue(searchBox.value)
        };
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.searchBoxFocus,
            type: InsightsRequestType.Post,
            data: data
        });
        this.debugMethod('Tracked search box focus');
    };
    SS360Insights.prototype.collectSearchBoxChange = function (searchBox) {
        this.pretrack();
        // const sessionId = this.sessionId;
        // const sbSelector = generateElementSelector(searchBox, true, true);
        // const searchBoxChanges = this.searchBoxChanges;
        // if (!(sbSelector in searchBoxChanges) || searchBoxChanges[sbSelector] === undefined) {
        // 	searchBoxChanges[sbSelector] = {
        // 		data: [],
        // 		siteId: this.siteId,
        // 		sessionId,
        // 		searchBoxId: sbSelector,
        // 		referrer: getStringValue(window.location.href)
        // 	};
        // }
        // const val = getStringValue(searchBox.value);
        // if (this.latestSearchBoxValues[sbSelector] === val) {
        // 	return; // no need to track empty change
        // }
        // this.suggestionQueryCache.query = searchBox.value;
        // this.suggestionQueryCache.timestamp = new Date().getTime();
        // this.latestSearchBoxValues[sbSelector] = val;
        // clearTimeout(searchBoxChanges[sbSelector].timeoutId);
        // searchBoxChanges[sbSelector].data.push({
        // 	timestamp: (new Date()).getTime(),
        // 	// @ts-ignore
        // 	query: getStringValue(sxQuery(searchBox).val())
        // });
        // searchBoxChanges[sbSelector].timeoutId = setTimeout(() => {
        // 	const data = searchBoxChanges[sbSelector];
        // 	data.data = JSON.stringify(data.data);
        // 	this.sendRequest({
        // 		url: this.config.baseUrl + this.config.endpoints.searchBoxBulkChange,
        // 		type: InsightsRequestType.Post,
        // 		data
        // 	});
        // 	searchBoxChanges[sbSelector] = undefined;
        // }, 300);
    };
    SS360Insights.prototype.trackSubmitSearch = function (query, searchBox, searchButton, searchBoxId) {
        if (!query) {
            return;
        }
        this.pretrack();
        this.searchQueryCache.query = query;
        this.searchQueryCache.timestamp = new Date().getTime();
        var request = this.createImageRequest(this.config.endpoints.searchSubmit, this.sessionId, {
            query: getStringValue(query),
            searchBoxId: searchBoxId !== undefined ? getStringValue(searchBoxId) : getStringValue(generateElementSelector(searchBox, true)),
            searchButtonId: getStringValue(generateElementSelector(searchButton, true)),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked submit search');
    };
    SS360Insights.prototype.trackSearchBoxAbandon = function (query, resultCount, searchBox) {
        if (query === undefined) {
            query = '';
        }
        this.pretrack();
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            query: query,
            resultCount: getNumberValue(resultCount),
            searchBoxId: generateElementSelector(searchBox, true, true),
            referrer: getStringValue(window.location.href)
        };
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.searchBoxAbandon,
            type: InsightsRequestType.Post,
            data: data
        });
        this.debugMethod('Tracked search box abandon');
    };
    SS360Insights.prototype.trackShowSuggests = function (searchBox, suggestBlock, suggestItem, query, resultCount, queryDuration, isEmptyState) {
        if (isEmptyState === void 0) { isEmptyState = false; }
        this.pretrack();
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            query: query,
            resultCount: getNumberValue(resultCount),
            searchBoxId: generateElementSelector(searchBox, true, true),
            referrer: getStringValue(window.location.href)
        };
        if (queryDuration !== undefined) {
            // @ts-ignore
            data.queryDuration = queryDuration;
        }
        var url = this.config.baseUrl + this.config.endpoints.suggestShow;
        if (isEmptyState) {
            url = "".concat(url).concat(url.indexOf('?') === -1 ? '?' : '&', "isEmptyState=true");
        }
        this.sendRequest({
            url: url,
            type: InsightsRequestType.Post,
            data: data
        });
        this.debugMethod('Tracked suggestions displayed');
    };
    SS360Insights.prototype.trackSelectSuggest = function (searchBox, suggestBlock, suggestItem, query, resultCount, position, link, contentGroup, source, articleNumber, identifier, isEmptyState) {
        if (isEmptyState === void 0) { isEmptyState = false; }
        this.pretrack();
        var url = this.config.endpoints.suggestSelect;
        var hasEventTarget = suggestItem !== null && suggestItem !== undefined;
        if (identifier === null || identifier === undefined) {
            // @ts-ignore
            identifier = hasEventTarget ? sxQuery(suggestItem).data('productIdentifier') : undefined;
        }
        if ((identifier === null || identifier === undefined) && hasEventTarget) {
            identifier = sxQuery(suggestItem).parents('.unibox__selectable', true).data('productIdentifier');
        }
        if ((articleNumber === undefined || articleNumber === null) && hasEventTarget) {
            // @ts-ignore
            articleNumber = sxQuery(suggestItem).data('ss360ArticleNumber') || sxQuery(suggestItem).data('productArticleNumber') || sxQuery(suggestItem).parents('.unibox__selectable', true).data('ss360ArticleNumber') || sxQuery(suggestItem).parents('.unibox__selectable', true).data('productArticleNumber');
        }
        var hasQueryParams = contentGroup !== undefined || source !== undefined || (identifier !== undefined && identifier !== null) || (articleNumber !== undefined && articleNumber !== null) || isEmptyState;
        var isFirst = true;
        if (hasQueryParams) {
            url = "".concat(url, "?");
        }
        if (contentGroup !== undefined) {
            url = "".concat(url, "contentGroup=").concat(encodeURIComponent(contentGroup));
            isFirst = false;
        }
        if (source !== undefined) {
            url = "".concat(url).concat(isFirst ? '' : '&', "source=").concat(encodeURIComponent(source));
            isFirst = false;
        }
        if (identifier !== undefined && identifier !== null && identifier !== '') {
            url = "".concat(url).concat(isFirst ? '' : '&', "identifier=").concat(encodeURIComponent(identifier));
            isFirst = false;
        }
        if (articleNumber !== undefined && articleNumber !== null && articleNumber !== '') {
            url = "".concat(url).concat(isFirst ? '' : '&', "articleNumber=").concat(encodeURIComponent(articleNumber));
            isFirst = false;
        }
        if (isEmptyState) {
            url = "".concat(url).concat(isFirst ? '' : '&', "isEmptyState=true");
        }
        link = normalizeLink(link);
        this.storeSearchInteraction(link, identifier, articleNumber);
        var request = this.createImageRequest(url, this.sessionId, {
            query: getStringValue(query !== undefined && query.length > 0 ? query : undefined),
            searchBoxId: getStringValue(generateElementSelector(searchBox, true)),
            resultCount: getNumberValue(resultCount),
            position: getNumberValue(position),
            link: getStringValue(link),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked search suggestion click');
    };
    SS360Insights.prototype.trackSerpShow = function (resultBlock, resultItem, query, resultCount, resultType, filterValues, resultsAvailable) {
        this.pretrack();
        var sessionId = this.sessionId;
        var queryDuration = this.searchQueryCache.query === query ? (new Date().getTime() - this.searchQueryCache.timestamp) : undefined;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            query: query,
            resultCount: getNumberValue(resultCount),
            searchResultType: getStringValue(resultType),
            referrer: getStringValue(window.location.href),
            filters: getStringValue(filterValues)
        };
        if (queryDuration !== undefined) {
            // @ts-ignore
            data.queryDuration = queryDuration;
        }
        var url = this.config.baseUrl + this.config.endpoints.serpShow;
        if (resultsAvailable !== undefined) {
            url = "".concat(url).concat((url.indexOf('?') === -1 ? '?' : '&'), "resultsAvailable=").concat(resultsAvailable);
        }
        this.sendRequest({
            url: url,
            type: InsightsRequestType.Post,
            data: data
        });
        this.debugMethod('Tracked search result page displayed');
    };
    SS360Insights.prototype.trackSerpLeave = function (resultBlock, resultItem, query, resultCount, resultType, resultsAvailable) {
        this.pretrack();
        var url = this.config.endpoints.serpAbandon;
        if (resultsAvailable !== undefined) {
            url = "".concat(url).concat((url.indexOf('?') === -1 ? '?' : '&'), "resultsAvailable=").concat(resultsAvailable);
        }
        var request = this.createImageRequest(url, this.sessionId, {
            query: getStringValue(query),
            resultCount: getNumberValue(resultCount),
            searchResultType: getStringValue(resultType),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked search result page abandoned');
    };
    SS360Insights.prototype.trackFilterInteraction = function (query, filterBlock, filterData) {
        this.pretrack();
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            query: query,
            referrer: getStringValue(window.location.href),
            filters: getStringValue(filterData)
        };
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.filterInteraction,
            type: InsightsRequestType.Post,
            data: data
        });
        this.debugMethod('Tracked filter interaction');
    };
    SS360Insights.prototype.trackAddToCart = function (articleNumber, identifier, link, count, price, priceUnit, fromSearch, fromSuggest) {
        if (fromSearch === void 0) { fromSearch = false; }
        if (fromSuggest === void 0) { fromSuggest = false; }
        if (!fromSearch && !fromSuggest && !this.comesFromSearch(link, identifier, articleNumber)) {
            this.debugMethod('Not tracking add to cart, because this product wasn\'t interacted with within this search session');
            return;
        }
        this.pretrack();
        var sessionId = this.sessionId;
        link = normalizeLink(link);
        var source = 'pdp';
        if (fromSearch) {
            source = 'serp';
        }
        else {
            source = 'suggest';
        }
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            link: link,
            count: count,
            priceUnit: priceUnit,
            referrer: getStringValue(window.location.href),
            source: source
        };
        if (price !== undefined && price !== null) {
            data.price = price;
        }
        if (identifier !== undefined && identifier !== null && identifier !== '') {
            data.identifier = identifier;
        }
        if (articleNumber !== undefined && articleNumber !== null && articleNumber !== '') {
            data.articleNumber = articleNumber;
        }
        if (fromSearch || fromSuggest) {
            this.storeSearchInteraction(link, identifier, articleNumber);
        }
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.addToCart,
            type: InsightsRequestType.Post,
            data: data
        });
        this.debugMethod('Tracked add to cart');
    };
    SS360Insights.prototype.trackBuy = function (products, trackAll) {
        var _this = this;
        products.forEach(function (p) { return p.link = normalizeLink(p.link); });
        products = trackAll ? products : products.filter(function (p) { return _this.comesFromSearch(p.link, p.identifier, p.articleNumber); });
        if (products.length === 0) {
            return;
        }
        products.forEach(function (p) {
            if (p.fromSearch === undefined) {
                p.fromSearch = trackAll ? _this.comesFromSearch(p.link, p.identifier, p.articleNumber) : true;
            }
        });
        this.pretrack();
        var sessionId = this.sessionId;
        var data = {
            siteId: this.siteId,
            sessionId: sessionId,
            timestamp: new Date().getTime(),
            referrer: getStringValue(window.location.href),
            products: JSON.stringify(products)
        };
        this.sendRequest({
            url: this.config.baseUrl + this.config.endpoints.buy,
            type: InsightsRequestType.Post,
            data: data
        });
        this.debugMethod('Tracked checkout');
    };
    SS360Insights.prototype.trackRelatedContentLoaded = function (query, duration, count, id, relationId, tag, originalQuery, isSerp) {
        if (isSerp === void 0) { isSerp = false; }
        this.pretrack();
        var queryParams = [];
        if (relationId !== undefined && relationId !== null) {
            queryParams.push("relationId=".concat(encodeURIComponent(relationId)));
        }
        if (tag !== undefined && tag !== null) {
            queryParams.push("tag=".concat(encodeURIComponent(tag)));
        }
        if (originalQuery !== undefined && originalQuery !== null) {
            queryParams.push("originalQuery=".concat(encodeURIComponent(originalQuery)));
        }
        if (isSerp) {
            queryParams.push("isSerp=true");
        }
        var queryString = queryParams.length === 0 ? '' : "?".concat(queryParams.join('&'));
        var request = this.createImageRequest("".concat(this.config.endpoints.relatedContentLoaded).concat(queryString), this.sessionId, {
            query: getStringValue(query),
            duration: getNumberValue(duration),
            count: getNumberValue(count),
            id: getStringValue(id),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked relations loaded');
    };
    SS360Insights.prototype.trackRelatedContentViewed = function (query, id, relationId, tag) {
        this.pretrack();
        var queryParams = [];
        if (relationId !== undefined && relationId !== null) {
            queryParams.push("relationId=".concat(encodeURIComponent(relationId)));
        }
        if (tag !== undefined && tag !== null) {
            queryParams.push("tag=".concat(encodeURIComponent(tag)));
        }
        var queryString = queryParams.length === 0 ? '' : "?".concat(queryParams.join('&'));
        var request = this.createImageRequest("".concat(this.config.endpoints.relatedContentViewed).concat(queryString), this.sessionId, {
            query: getStringValue(query),
            id: getStringValue(id),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked relations viewed');
    };
    SS360Insights.prototype.trackRelatedContentClicked = function (query, id, resultLink, position, relationId, tag, identifier) {
        this.pretrack();
        var queryParams = [];
        if (relationId !== undefined && relationId !== null) {
            queryParams.push("relationId=".concat(encodeURIComponent(relationId)));
        }
        if (tag !== undefined && tag !== null) {
            queryParams.push("tag=".concat(encodeURIComponent(tag)));
        }
        if (identifier !== undefined && identifier !== null) {
            queryParams.push("identifier=".concat(encodeURIComponent(identifier)));
        }
        var queryString = queryParams.length === 0 ? '' : "?".concat(queryParams.join('&'));
        var request = this.createImageRequest("".concat(this.config.endpoints.relatedContentClicked).concat(queryString), this.sessionId, {
            query: getStringValue(query),
            id: getStringValue(id),
            referrer: getStringValue(window.location.href),
            resultLink: getStringValue(resultLink),
            position: getNumberValue(position)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked relations result clicked');
    };
    SS360Insights.prototype.trackGuidedQuestionLoaded = function (query, id, viewId, duration) {
        this.pretrack();
        var request = this.createImageRequest("".concat(this.config.endpoints.guidedQuestionLoaded), this.sessionId, {
            query: getStringValue(query),
            id: getNumberValue(id),
            viewId: getNumberValue(viewId),
            duration: getNumberValue(duration),
            referrer: getStringValue(window.location.href)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked guided questions loaded');
    };
    SS360Insights.prototype.trackGuidedQuestionViewed = function (query, viewId) {
        this.pretrack();
        var request = this.createImageRequest("".concat(this.config.endpoints.guidedQuestionViewed), this.sessionId, {
            query: getStringValue(query),
            viewId: getNumberValue(viewId)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked guided questions viewed');
    };
    SS360Insights.prototype.trackGuidedQuestionClicked = function (query, viewId, position, answerId) {
        this.pretrack();
        var queryParam = answerId !== undefined && answerId !== null ? "?answerId=".concat(answerId) : '';
        var request = this.createImageRequest("".concat(this.config.endpoints.guidedQuestionClicked).concat(queryParam), this.sessionId, {
            query: getStringValue(query),
            viewId: getNumberValue(viewId),
            position: getNumberValue(position)
        });
        this.sendRequest(request);
        this.debugMethod('Tracked guided question selected');
    };
    SS360Insights.prototype.setBaseUrl = function (baseUrl) {
        this.config.baseUrl = baseUrl;
    };
    SS360Insights.prototype.setAdditionalParams = function (opts) {
        this.additionalParameters = opts;
    };
    SS360Insights.prototype.storeSearchInteraction = function (link, identifier, articleNumber) {
        if (!this.allowCookies || !this.hasCheckoutTracking) {
            return;
        }
        this.storeSearchInteractionEntry(SELECTED_LINK_KEY, link);
        this.storeSearchInteractionEntry(SELECTED_IDENTIFIER_KEY, identifier);
        this.storeSearchInteractionEntry(SELECTED_ARTICLE_NUMBER_KEY, articleNumber);
    };
    SS360Insights.prototype.comesFromSearch = function (link, identifier, articleNumber) {
        return (link !== undefined && link !== null && link !== '' && this.loadSearchInteractionEntry(SELECTED_LINK_KEY).data.indexOf(link) !== -1) ||
            (identifier !== undefined && identifier !== null && identifier !== '' && this.loadSearchInteractionEntry(SELECTED_IDENTIFIER_KEY).data.indexOf(identifier) !== -1) ||
            (articleNumber !== undefined && articleNumber !== null && articleNumber !== '' && this.loadSearchInteractionEntry(SELECTED_ARTICLE_NUMBER_KEY).data.indexOf(articleNumber) !== -1);
    };
    SS360Insights.prototype.storeSearchInteractionEntry = function (key, value) {
        if (value !== undefined && value !== null && value !== '') {
            // @ts-ignore
            var storedData = this.loadSearchInteractionEntry(key);
            if (storedData.data.indexOf(value) === -1) {
                storedData.data.push(value);
                storedData.updated = new Date().getTime();
                SxQueryUtils._storeObject(this.getSearchInteractionEntryKey(key), storedData, CHECKOUT_RETENTION_DAYS);
            }
        }
    };
    SS360Insights.prototype.getSearchInteractionEntryKey = function (baseKey) {
        return "".concat(baseKey).concat(this.cookiePostfix);
    };
    SS360Insights.prototype.loadSearchInteractionEntry = function (key) {
        // @ts-ignore
        return SxQueryUtils._readObject(this.getSearchInteractionEntryKey(key), { data: [], updated: undefined });
    };
    SS360Insights.prototype.setHasCheckoutTracking = function (val) {
        this.hasCheckoutTracking = val;
    };
    return SS360Insights;
}());
export default SS360Insights;
